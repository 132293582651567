<template>
  <div>
    <div class="eng_area">
        <h5><li class="fas fa-building"></li> 선택 건물 리스트        <code
                class="downBtn"
                @click="excelDown()"
              >
                <feather-icon icon="DownloadIcon" />
                엑셀다운로드</code>
    </h5>
    </div>
    <b-table
      id="lassoChoice"
      :fields="fields"
      :items="items"
      table-class="edocoi-table"
      thead-class="edocoi-thead"
      tbody-class="edocoi-tbody"
      resposive
      sticky-header
      style="white-space:nowrap"
    />
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue'
import { utils } from "@/utils/commUtils";
import { mapGetters } from "vuex";

export default {
  components: {
    BTable
  },
  props: {
    mgmBldPkLasso: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      mainSelected: "main/getSelectedCodeData",
      filterSelected: "filter/getFilterFetchedInfo",
      result: "filter/getResult",
    }),
    checkUseYy() {
      return this.mainSelected.useYyLasooCd.cdId;
    },
  },
  watch: {
    checkUseYy(val) {
      this.mainSelected.useYyLasooCd.cdId = val
      // 페이지 생성 시 데이터 불러옴
      this.$store.dispatch("main/FETCH_BUILD_LASSO_CHOICE_DATA", {
        dataCode: "buildLassoChoice",
        mgmBldPkLasso: this.mgmBldPkLasso,
        useYyLasooCd: this.mainSelected.useYyLasooCd.cdId,
      }).then(() => {
        this.items = this.$store.state.main.buildLassoChoice;
      }).catch(() => {
        console.error('오류')
      })
    }

  },
  data() {
    return {

      fields: [
        {
          key: 'bldnm', label: '건물명', class:'bldnm-class',
        },
        {
          key: 'addr', label: '주소', class:'addr-class',
        },
        {
          key: 'mainpurpsnm', label: '건물용도', class:'mainpurpsnm-class',
        },
        {
          key: 'totarea', label: '연면적(m²)', class:'totarea-class',
        },
        {
          key: 'energyqty', label: '에너지소비량(kWh/m²)', class:'energyqty-class',
        },
        {
          key: 'sudoqty', label: '수도소비량 (Ton)', class:'sudoqty-class',
        },
      ],
      items: [],

    }
  },
  created() {
    this.loadBuildLassoChoice();
  },
  methods: {
    excelDown() {
      //utils.fnAlert(this, '다운로드 권한이 없습니다');
       utils.fnConfirm(this, '다운로드 하시겠습니까?', '엑셀 다운로드시 일정 시간이 소요될 수 있습니다.')
         .then(async result => {
           if (result.value) {
             const aJson = {};
      
             aJson.useYyCd = this.mainSelected.useYyLasooCd;
             aJson.openSvcId = this.mgmBldPkLasso;
      
             await this.$store.dispatch("filter/DOWNLOAD_EXCEL", aJson);
             if (!this.result.resultFlag) {
               utils.fnAlert(this, this.result.resultMsg);
             }
           }
         });
    },
    loadBuildLassoChoice() {
      // 페이지 생성 시 데이터 불러옴
      this.$store.dispatch("main/FETCH_BUILD_LASSO_CHOICE_DATA", {
        dataCode: "buildLassoChoice",
        mgmBldPkLasso: this.mgmBldPkLasso,
        useYyLasooCd: this.mainSelected.useYyLasooCd.cdId,
      }).then(() => {
        this.items = this.$store.state.main.buildLassoChoice;
      }).catch(() => {
        console.error('오류')
      })
    }
  },

}
</script>
<style>
#lassoChoice th {
  text-transform: none;
}
.edocls-table{
   border:1px solid #cccccc;
}

.edocoi-thead th[class$="-class"]{
    background-color: #f3f2f7 !important;
    padding: 3px 0px 3px 0px;
    text-align: center;
    font-size :  11px;
}
.edocoi-tbody td.bldnm-class{
    text-align: left;
    width:20%;
}
.edocoi-tbody td.addr-class{
    text-align: left;
    width:20%;
}
.edocoi-tbody td.mainpurpsnm-class{
    text-align: center;
    width:15%;
}
.edocoi-tbody td.totarea-class{
    text-align: right;
    width:15%;
}
.edocoi-tbody td.energyqty-class{
    text-align: right;
    width:15%;
}
.edocoi-tbody td.sudoqty-class{
    text-align: right;
    width:15%;
}

.edocoi-tbody td[class$="-class"]{
    padding: 2px 2px;
    border: 1px solid #cccccc;
    border-radius: 0px;
    min-width: 100px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}
</style>
<style scoped>
.table-style {
  /*position: relative;*/
  /*white-space: nowrap;*/
}

.downBtn {
  cursor: pointer;
}

.downBtn:hover {
  background-color: #28c76f;
  color: #ffffff;
}
</style>
