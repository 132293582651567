<template>
  <div>
		<b-row>
      <b-col cols="8">
        <div class="mb-2">
              <h3>
              <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
              </h3>  
          </div>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label-for="useYyCd"
        >
          <v-select
            id="useYyCd"
            v-model="mainSelected.useYyLasooCd"
            label="cdNm"
            taggable
            push-tags
            :options="commCodeData.USE_YY_CD"
            @input="onClickEnergyUseInfo($event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
		<div class="lassoInfoTopWrap">
			<div class="lassoInfoTopWrapLeft">
				<div class="eng_area">
					<h5><li class="fas fa-bolt"></li> 에너지 소비량 <span class="info">소비량 분리 (단위환산, 1MJ=0.278kWh, 1Mcal = 1.163kWh)</span></h5>
				</div>
				<b-table
					id="lassoStmt"
                    class="table-style"
					:items="items"
					:fields="fields"
					resposive
                    table-class="edocls-table"
                    thead-class="edocls-thead"
                    tbody-class="edocls-tbody"
                    sticky-header
				/>
			</div>
			<div class="lassoInfoTopWrapRight">
				<div class="eng_area">
					<h5><li class="fas fa-fire"></li> 지열 에너지 잠재량
					<!--span class="menuHelp" @mouseout="mout" @mouseover="mover">
						<img src="@/assets/images/logo/help.png" style="width:20px;" />
					</span-->
                    </h5>
					<span v-show="helpBoxShow" class="menuHelpBox heatEnergyExp">
						<h5 style="font-weight:300;">지열에너지 포텐셜 계산</h5>
						(0) 건폐율 80% 이하 건물 선정<br>
						(1) 연면적 [㎡] X 0.107 [kW/㎡] X 0.8 = 000kW (히트펌프 용량)<br>
						(2) (대지 면적 - 건축 면적) [㎡] X 0.8 = 000 ㎡ (수직 지중열교환기 설치 가용 면적)<br>
						(3-1) 가용 면적(2항)÷36 [㎡/홀] = 000홀 (CASE 1의 수직 지중열교환기 개수)<br>
						(3-2) 가용 면적(2항)÷25 [㎡/홀] = 000홀 (CASE 2의 수직 지중열교환기 개수)<br>
						(3-3) 가용 면적(3항)÷16 [㎡/홀] = 000홀 (CASE 3의 수직 지중열교환기 개수)<br>
						(4-1) 수직 지중열교환기 개수(3-1항)X10 [kW/홀] = 000kW (CASE 1 용량)<br>
						(4-2) 수직 지중열교환기 개수(3-2항)X10 [kW/홀] = 000kW (CASE 2 용량)<br>
						(4-3) 수직 지중열교환기 개수(3-3항)X10 [kW/홀] = 000kW (CASE 3 용량)<br>
					</span>
				</div>
				<div>
					<table class="edocls-table">
						<thead class="edocls-thead">
							<tr>
								<th rowspan="2" class="capacity-class">
	히트펌프 용량
	</th>
								<th colspan="3" class="capacity-class">
	수직 지중열교환기(CASE 1 기준)
	</th>
							</tr>
							<tr>
								<th class="area-class">
	설치 가용 면적
	</th>
								<th class="case1cnt-class">
	개수
	</th>
								<th class="case2cnt-class">
	용량
	</th>
							</tr>
						</thead>
						<tbody  class="edocls-tbody">
							<tr>
								<td class="capacity-class">
									{{ heatPumpCap | toFixed(1) }}kW
	</td>
								<td class="area-class">
									{{ uafioVghe | toFixed(1) }}㎡
	</td>
								<td class="case1cnt-class">
									{{ vgheQty1 | toFixed(1) }}개
	</td>
								<td class="case2cnt-class">
									{{ vgheCap1 | toFixed(1) }}kW
	</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div>
					<echart-bar-heat
          :series-data1="seriesData1"
          :series-data2="seriesData2"
           />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { BTable, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import { mapGetters } from "vuex";
import vSelect from 'vue-select'
import EchartBarHeat from '@/views/building/EchartEnergy/EchartBarHeat';

export default {
  components: {
    BTable, BRow, BCol, vSelect, BFormGroup, EchartBarHeat,
  },
	filters: {
    toFixed(val, num) {
      const out = Number(parseFloat(val).toFixed(num));

      if (isNaN(out)) {
        return '-';
      }

      return out;
    },
  },
  props: {
    mgmBldPkLasso: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      commCodeData: "main/getCommCodeData",
      mainSelected: "main/getSelectedCodeData",
    }),
  },
  data() {
    return {
			helpBoxShow: false,
			newEnergyHeatData: {},
			heatPumpCap: 0,
      uafioVghe: 0,
      vgheQty1: 0,
      vgheQty2: 0,
      vgheQty3: 0,
      vgheCap1: 0,
      vgheCap2: 0,
      vgheCap3: 0,
      seriesData1: [],
      seriesData2: [],
      fields: [
        {
          key: 'mainpurpsnm', label: '건물용도', class: 'mainpurpsnm-class',
        },
        {
          key: 'buildcnt', label: '건물 수(개)',class: 'buildcnt-class',
        },
        {
          key: 'energyqty', label: '에너지 소비량(kWh/m²)',class: 'energyqty-class',
        },
        {
          key: 'energy1st', label: '1차 에너지 소비량(kWh/m²)',class: 'energy1st-class',
        },
        {
          key: 'energyco2', label: 'CO2 배출량(tCO₂/m²)',class: 'energyco2-class',
        },
        {
          key: 'totareasum', label: '연면적 합계(m²)',class: 'totareasum-class',
        },
        {
          key: 'totareaavg', label: '연면적 평균(m²)',class: 'totareaavg-class',
        },
      ],
      items: [],
			potentialFields: [
        {
          key: 'capacity', label: '히트펌프 용량',class: 'capacity-class',
        },
        {
          key: 'area', label: '설치 가용 면적',class: 'area-class',
        },
        {
          key: 'case1cnt', label: '개수(CASE 1 기준)',class: 'case1cnt-class',
        },
        {
          key: 'case2cnt', label: '개수(CASE 2 기준)',class: 'case2cnt-class',
        },
      ],
			potentialItems: [
				{ capacity: '398.2kW', area: '1096.8m²', case1cnt: '30.5개', case2cnt: '304.7kW' },
			],
    }
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.onClickEnergyUseInfo();
		this.loadNewEnergyHeat();
  },
  methods: {
    onClickEnergyUseInfo() {
      this.$store.dispatch("main/FETCH_BUILD_LASSO_STMT_DATA", {
        dataCode: "buildLassoStmt",
        mgmBldPkLasso: this.mgmBldPkLasso,
        useYyLasooCd: this.mainSelected.useYyLasooCd.cdId,
      }).then(() => {
        this.items = this.$store.state.main.buildLassoStmt;
      }).catch(() => {
        console.error('오류')
      })
    },
		async loadNewEnergyHeat() {
			for (const item of this.mgmBldPkLasso) {
				await this.$store
        .dispatch('main/FETCH_NEW_ENERGY_HEAT_DATA', { mgmBldPk: item })
        .then(() => {
          this.newEnergyHeatData = this.$store.state.main.newEnergyHeat;
          this.heatPumpCap += Number(this.newEnergyHeatData.heatPumpCap);
          this.uafioVghe += Number(this.newEnergyHeatData.uafioVghe);
          this.vgheQty1 += Number(this.newEnergyHeatData.vgheQty1);
          this.vgheQty2 += Number(this.newEnergyHeatData.vgheQty2);
          this.vgheQty3 += Number(this.newEnergyHeatData.vgheQty3);
          this.vgheCap1 += Number(this.newEnergyHeatData.vgheCap1);
          this.vgheCap2 += Number(this.newEnergyHeatData.vgheCap2);
          this.vgheCap3 += Number(this.newEnergyHeatData.vgheCap3);
        })
        .catch(() => {
          console.log('실패');
        });
      }

			this.seriesData1.push(this.vgheQty1.toFixed(2));
			this.seriesData1.push(this.vgheQty2.toFixed(2));
			this.seriesData1.push(this.vgheQty3.toFixed(2));

			this.seriesData2.push(this.vgheCap1.toFixed(2));
			this.seriesData2.push(this.vgheCap2.toFixed(2));
			this.seriesData2.push(this.vgheCap3.toFixed(2));
		},
		mover() {
      this.helpBoxShow = true;
    },
    mout() {
      this.helpBoxShow = false;
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
#lassoStmt th {
  text-transform: none;
}

.buildLassoInfoTopText{
	color:red;
	margin-top:10px;
}
.lassoInfoTopWrap{
	display:flex;
}
.lassoInfoTopWrapLeft{
	width:55%;
}
.lassoInfoTopWrapRight{
	width:45%;
	padding-left:20px;
}
.topLeftTitle{
	font-size:15px;
	color:#5e5873;
	display:inline-block;
	width:22%;
}
.topLeftTitleExp{
	text-align:right;
	display:inline-block;
	width:78%;
}
.heatEnergyExp{
	top: 55px;
	right: -95px;
	width:520px;
	line-height:28px;
    font-size:11px;
}
.table-style{
   border:1px solid #cccccc;
}
.table th{
    background-color: #f3f2f7 !important;
    padding: 1px 0px 1px 0px;
    text-align: center;
    font-size : 12px;
    font-weight: bold;
	vertical-align:middle;
}
.table td{
	border:1px solid #cccccc;
    font-size : 12px;
}
.edocls-table{
   width:100%;
   border:1px solid #cccccc;
}

.edocls-thead th[class$="-class"]{
    background-color: #f3f2f7 !important;
    padding: 3px 0px 3px 0px;
    text-align: center;
    font-size :  11px;
}

.edocls-tbody td.mainpurpsnm-class{
    text-align: left;
}
.edocls-tbody td.buildcnt-class{
    text-align: center;
}
.edocls-tbody td.energyqty-class{
    text-align: right;
}
.edocls-tbody td.energy1st-class{
    text-align: right;
}
.edocls-tbody td.energyco2-class{
    text-align: right;
}
.edocls-tbody td.totareasum-class{
    text-align: center;
}
.edocls-tbody td.totareaavg-class{
    text-align: center;
}
.edocls-tbody td.capacity-class{
    text-align: center;
    width:30%;
}
.edocls-tbody td.area-class{
    text-align: right;
    width:20%;
}
.edocls-tbody td.case1cnt-class{
    text-align: center;
    width:25%;
}
.edocls-tbody td.case2cnt-class{
    text-align: right;
    width:25%;
}
.edocls-tbody td[class$="-class"]{
    font-size :  12px;
    padding: 2px 2px;
    border: 1px solid #cccccc;
    border-radius: 0px;
    min-width: 100px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}
</style>
