<template>
  <div>
		<div style="padding:10px;">
			<div class="h4 font-weight-bolder">
				주용도 검색
			</div>
			<b-row>
				<b-col class="h4 p-0">
					<b-form-checkbox plain @change="onChangeAll">
						전체
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-row class="defaultMainPurpsBox">
				<b-col class="h4 font-weight-bolder">
					<b-form-checkbox
						v-for="code in commCodeData.MAIN_PURPS_CD"
						:key="code.cdId"
						v-model="mainSelected.MAIN_PURPS_CD"
						:value="code"
                        class="checkbox-item"
						plain
					>
						{{ code.cdNm }}
					</b-form-checkbox>
				</b-col>
			</b-row>
		</div>
		<div class="mainPurpsMoreArea" @click="moreClk">
			<div v-show="moreBtnFlag">
▼ 더 보기
</div>
			<div v-show="!moreBtnFlag">
▲ 접기
</div>
		</div>
  </div>
</template>

<script>
import { BRow, BCol, BFormCheckbox, BDropdownDivider } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      commonEnergy: {
        useYyCd: null,
        engyKindCd: null,
        clsfKindCd: null,
        engyAllQtyCd: null,
        engyUse: null,
        prupsCd: [],
      },
      purpsArr: [],
      selectedAll: false,
			moreBtnFlag: true,
    };
  },
  computed: {
    ...mapGetters({
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
      engyUseChange: 'main/getEngyUseChange',
    }),
  },

  watch: {
    'mainSelected.MAIN_PURPS_CD': function(val) {
   //   this.resetCommonEnergy();

      this.purpsArr = [];

      if (val.length > 0) {
        val.forEach((item) => {
          const temp = {};
          temp.prupsCd = item.cdId;

          if (!this.purpsArr.includes(item.cdId)) {
            this.purpsArr.push(item.cdId);
          }

          this.commonEnergy.useYyCd = this.mainSelected.useYyCd.cdId;
          this.commonEnergy.engyKindCd = this.mainSelected.engyKindCd.cdId;
          this.commonEnergy.clsfKindCd = this.mainSelected.clsfKindCd.cdId;
          this.commonEnergy.engyAllQtyCd = this.mainSelected.engyAllQtyCd.cdId;
          this.commonEnergy.engyUse = this.mainSelected.engyUse.cdId;

          this.commonEnergy.prupsCd = this.purpsArr;
        });
      } else {
        this.resetCommonEnergy();
      }
      gis.search.changeEnergyFilter(this.commonEnergy);
    },
  },
  created() {
    // 공통코드 불러오기
    this.$store.dispatch('main/FETCH_COMM_CODE_DATA');
  },
  mounted() {},
  methods: {
    onChangeAll() {
      this.selectedAll = !this.selectedAll;
      if (this.selectedAll) {
        this.mainSelected.MAIN_PURPS_CD = this.commCodeData.MAIN_PURPS_CD;
      } else {
        this.mainSelected.MAIN_PURPS_CD = [];
        this.resetCommonEnergy();
      }
    },
    resetCommonEnergy() {
     // this.commonEnergy.useYyCd = null;
     // this.commonEnergy.engyKindCd = null;
     // this.commonEnergy.clsfKindCd = null;
     // this.commonEnergy.engyAllQtyCd = null;
     // this.commonEnergy.engyUse = null;
      this.commonEnergy.prupsCd = [];

    //  gis.search.changeEnergyFilter(this.commonEnergy);
    },
		moreClk() {
			this.moreBtnFlag = !this.moreBtnFlag;

			if (this.moreBtnFlag) {
				document.getElementsByClassName('defaultMainPurpsBox')[0].style.height = "150px";
			} else {
				document.getElementsByClassName('defaultMainPurpsBox')[0].style.height = "auto";
			}
		},
  },
};
</script>

<style scoped>
.form-check {
  display: inline-block;
}
/*
.defaultMainPurpsBox{
	height:150px;
	overflow-y:hidden;
}
*/
.mainPurpsMoreArea{
	border-top: 1px solid;
	padding: 5px;
	text-align: center;
	cursor: pointer;
}

.defaultMainPurpsBox{
    display: flex;
    flex-wrap: wrap;
    height:150px;
    width: 240px;
    overflow-y:hidden;
}

.defaultMainPurpsBox .checkbox-item {
  width: 50%; /* 2열로 배치하기 위해 각 체크박스를 50%로 설정 */
  box-sizing: border-box; /* 패딩과 테두리를 너비에 포함 */
  padding: 0px 0px 0px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임표 처리 */
}

.defaultMainPurpsBox b-form-checkbox:last-child {
  margin-right: 0; /* 마지막 체크박스의 오른쪽 간격 제거 */
}
</style>
