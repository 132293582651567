<template>
  <div>
    <b-row>
      <b-col cols="10">
        <b-form-group>
          <!-- @keyup.enter 이벤트 추가 -->
          <b-form-input v-model="areaSearch" placeholder="검색어를 입력해주세요(도로명, 건물명)" @keyup.enter="toggleList" />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-button size="sm" variant="relief-primary" style="width: 60px;margin-left:-25px;font-size:12px;" @click="toggleList">
          {{ listBtn }}
        </b-button>
      </b-col>
    </b-row>
    <b-row v-show="listShow" class="address-address-list">
      <b-col>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <!-- row slot -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'roadAddrPart1'">
              <div @click="onRowClick(props.row)">
                <a href="javascript:;">
                  {{ props.row.roadAddrPart1 }}
                </a>
              </div>
              <div>{{ props.row.jibunAddr }}</div>
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-center flex-wrap">
              <div>
                <b-pagination
                  size="sm"
                  :value="1"
                  :total-rows="totalCount"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="next-item"
                  next-class="prev-item"
                  class="mt-1 mb-0"
                  @input="(value) => goPage(value)"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="12" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="12" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow, BPagination } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { mapGetters } from 'vuex';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    VueGoodTable,
    BPagination,
  },
  data() {
    return {
      areaSearch: '',
      listShow: false,
      pageLength: 5,
      totalCount: 10,
      columns: [
        {
          label: '도로명 주소/지번주소',
          field: 'roadAddrPart1',
        },
        {
          label: '우편번호',
          field: 'zipNo',
        },
      ],
      rows: [],
    };
  },
  computed: {
    listBtn() {
      return this.listShow ? '닫기' : '검색';
    },
  },
  methods: {
    async onRowClick(val) {
      const data = await this.$store.dispatch('main/FETCH_SEARCH_COORD_DATA', {
        admCd: val.admCd,
        rnMgtSn: val.rnMgtSn,
        buldMnnm: val.buldMnnm,
        buldSlno: val.buldSlno,
        udrtYn: val.udrtYn,
        confmKey: 'U01TX0FVVEgyMDIzMDYxNjExMDkxMzExMzg1NTk=',
      });

      // gis.map.setCenter(map, { centerCoord: [data.juso[0].entX, data.juso[0].entY], zoomLvl: 11 });

      // const addrCrd = [parseInt(data.juso[0].entX), parseInt(data.juso[0].entY)];
      // const param = { centerCoord : addrCrd,
      //                 zoomLvl : 13,
      //                 objAttr : data.juso[0]
      //               };
      // console.log (param);
      // gis.map.setCenter(map, param);
      if (data != null && typeof data !== 'undefined' && data.juso[0] != null && !isNaN(data.juso[0].entX) && !isNaN(data.juso[0].entY)) {
        // const addrCrd = { x : parseInt(data.juso[0].entX), y : parseInt(data.juso[0].entY) };
        // gis.search.moveToCrdAndMarker(map, addrCrd,  data.juso[0]);
        const param = { coord: { epsg: 'EPSG:5179', x: parseInt(data.juso[0].entX), y: parseInt(data.juso[0].entY), z: 13 }, attr: data.juso[0] };
        gis.search.moveToCrdAndMarker(param);
      } else {
        console.error('선택된 도로명 주소로 도로명 좌표 정보 가져오기 API수신 오류 : ', data);
      }
    },
    async goPage(val) {
      if (this.areaSearch === '') {
        alert('지역을 입력하세요.');
        this.listShow = false;
        return;
      }

      const data = await this.$store.dispatch('main/FETCH_ADDRESS_SEARCH_DATA', {
        currentPage: val,
        countPerPage: 5,
        keyword: this.areaSearch,
        confmKey: 'U01TX0FVVEgyMDI0MDMyMjExMzg1MzExNDYyNDQ=',
      });

      this.totalCount = data.common.totalCount;
      this.rows = data.juso;
    },
    toggleList() {
      if (this.listShow) {
        this.listShow = false;
      } else {
        this.listShow = true;
        this.goPage(1);
      }
    },
  },
};
</script>

<style>
.line-numbers {
  width: 30px;
  text-align: center;
}
.address-address-list {
  width: 410px;
  background-color: #ffffff;
  margin-top: -10px;
  margin-left: 0px;
  margin-right: -40px;
  padding: 1px;
  border: 1px solid #ccc; /* 테두리 */
  border-radius: 8px; /* 둥근 모서리 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 약간의 그림자 */
}
table {
  border: 0px solid #cccccc;
  text-align: left;
  font-size: 100%;
  width: 385px;
}

th {
  text-align: center;
  font-size: 12px;
  padding: 5px 0px;
}

td {
  font-size: 12px;
}
td:nth-child(1) {
  width: 320px;
}
td:nth-child(2) {
  text-align: center;
}
</style>
